<template>
  <!-- 数字通证限制-->
  <div>
    <!-- 顶部选择框 -->
    <div style="padding: 30px; display: flex; justify-content: space-between">
      <div>
        <el-radio-group v-model="tabls">
          <div style="display: flex">
            <div>
              <el-radio-button :label="true" v-model="cater"
                >提数字通证审核</el-radio-button
              >
            </div>
            <div @click="erCgat">
              <el-radio-button :label="false" v-model="cater"
                >提资金限制</el-radio-button
              >
            </div>
          </div>
        </el-radio-group>
      </div>
      <div>
        <el-button type="warning" @click="addrisAssessment">新增</el-button>
      </div>
    </div>
    <!-- 顶部查询 -->
    <div class="form">
      <!-- 输入手机号码查询 -->
      <div>
        <span>按手机号码查询</span>
        <div class="flexcer">
          <div>
            <el-input
              v-model="form.phone"
              placeholder="请输入正确的手机号"
              prefix-icon="el-icon-mobile-phone"
              style="width: 180px"
            ></el-input>
          </div>
        </div>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="height: 40px; margin-top: 40px"
          @click="doFilter"
          >搜索</el-button
        >
      </div>
    </div>

    <!-- 数字通证限制 -->
    <div v-show="tabls">
      <!-- 表格 -->
      <div style="margin-top: 30px">
        <el-table
          ref="multipleTable"
          tooltip-effect="dark"
          style="width: 100%"
          border=""
        >
          <el-table-column label="序号" width="120"> </el-table-column>
          <el-table-column label="手机号" width="140"> </el-table-column>
          <el-table-column label="姓名"> </el-table-column>
          <el-table-column label="限制数字通证"> </el-table-column>
          <el-table-column label="限制数字通证类型"> </el-table-column>
          <el-table-column label="操作人"> </el-table-column>
          <el-table-column label="操作时间"> </el-table-column>
          <el-table-column label="操作"> </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 提资金限制 -->
    <div v-show="!tabls">
      <div></div>
      <div style="margin-top: 30px">
        <el-table border>
          <el-table-column label="序号" width="120"></el-table-column>
          <el-table-column label="手机号" width="140"></el-table-column>
          <el-table-column label="姓名"></el-table-column>
          <el-table-column label="资金"></el-table-column>
          <el-table-column label="操作人"></el-table-column>
          <el-table-column label="操作时间"></el-table-column>
          <el-table-column label="操作"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="23"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cater: "", //提数字通证审核
      value: "1",
      disable: true, // 按钮状态
      tabls: true,
      Tnum: 0, // 提数字通证总数
      Cnum: 0, // 充数字通证总数
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      form: {
        time1: "",
        time2: "",
        phone: "",
        turnsOutAddress: "",
        userName: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  created() {
    let arr = [
      { id: 1, num: 100 },
      { id: 2, num: 100 },
      { id: 3, num: 100 },
      { id: 4, num: 100 },
      { id: 5, num: 100 },
    ];
    arr.forEach(item => {
      item.num = item.num.toFixed(4);
    });
    console.log(arr);
  },
  methods: {
    // 点击跳转新增
    addrisAssessment() {
      console.log(123);
      console.log(this.value);
      if (!this.value) {
        this.$router.push("addrisAssessment");
      } else {
        this.$router.push("addverifyGet");
      }
    },
    erCgat() {
      this.value = "1";
      console.log(this.value);
    },
    //   搜索
    doFilter() {},
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  div {
    margin-right: 3px;
    span {
      //   font-weight: bold;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 100px;
}
.text {
  color: #848484;
  margin: 0 20px;
  span {
    margin-left: 10px;
    color: red;
  }
}
</style>
